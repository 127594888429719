<template>
  <v-container fluid>
    <v-card>

      <v-tabs v-model="currentTab">
        <v-tab v-for="tab in tabs" :key="tab">{{ tab }}</v-tab>
      </v-tabs>

      <div class="tab-content">
        <div v-show="currentTab === 0">

          <v-app-bar>
            <v-form
              @submit.prevent="pieReport(layerTarget, typeTarget, districtTarget)"
            >
              <v-row dense>
                <v-col cols="12" sm="2">
                  <v-select
                    :items="layers"
                    v-model="layerTarget"
                    item-text="name"
                    item-value="id"
                    placeholder="Слои"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-select
                    :items="types"
                    v-model="typeTarget"
                    item-text="name"
                    item-value="id"
                    placeholder="Объекты озеленения"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" sm="2">
                  <v-select
                    :items="districts"
                    v-model="districtTarget"
                    item-text="name"
                    item-value="id"
                    placeholder="Район"
                    outlined
                    dense
                    hide-details
                    class="mb-1"
                  />
                </v-col>
                <v-col cols="12" sm="2">
                  <v-select
                    :items="reports"
                    v-model="reportsTarget"
                    item-text="report"
                    item-value="id"
                    placeholder="Виды отчетов"
                    outlined
                    dense
                    hide-details
                    class="mb-1"
                  />
                </v-col>
                <v-col cols="12" sm="2" align-self="center">
                  <v-btn
                    type="submit"
                    outlined
                    color="primary"
                    :disabled="!typeTarget || !reportsTarget"
                  >
                    Сформировать отчет
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-app-bar>

          <v-row no-gutters class="mt-2">
            <v-col cols="12" sm="12">
              <v-card class="ma-8 pa-4" elevation="20">
                <v-row dense>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="3">
                    <v-select
                      :items="printChart"
                      v-model="printTarget"
                      item-text="name"
                      item-value="id"
                      placeholder="Печать отчета"
                      outlined
                      dense
                      hide-details
                      @change="printer"
                      :disabled="!chart"
                    />
                  </v-col>
                </v-row>
                <div
                  class="mt-10"
                  style="display: flex; justify-content: center"
                  v-if="loading"
                >
                  <Loader />
                </div>
                <pie
                  v-else-if="chart"
                  :chart-data="chartData"
                  :options="chartOptions"
                  style="height: 450px; width: 100%;"
                  ref="refPie"
                ></pie>
                <template v-else-if="table">
                  <v-row dense>
                    <v-spacer></v-spacer>
                    <v-btn
                      type="submit"
                      outlined
                      color="primary"
                      @click="
                    tableReportExcel(layerTarget, typeTarget, districtTarget)
                  "
                    >
                      Выгрузить в Excel
                    </v-btn></v-row
                  >
                  <v-data-table
                    :headers="headers"
                    :items="plants"
                    dense
                  ></v-data-table>
                </template>
              </v-card>
            </v-col>
          </v-row>

        </div>
        <div v-show="currentTab === 1">
          <v-app-bar>
            <v-row dense>
              <v-col cols="12" sm="3">
                <v-select
                  :items="waterTypes"
                  v-model="waterTarget"
                  item-text="name"
                  item-value="id"
                  placeholder="Водные объекты"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
            </v-row>
          </v-app-bar>

          <v-row no-gutters class="mt-2">
            <v-col cols="12" sm="12">
              <v-card class="ma-8 pa-4" elevation="20">
                <v-row dense>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="3">
                    <v-select
                      :items="printChart"
                      v-model="printTarget"
                      item-text="name"
                      item-value="id"
                      placeholder="Печать отчета"
                      outlined
                      dense
                      hide-details
                      @change="printer"
                    />
                  </v-col>
                </v-row>
                <pie
                  :chart-data="chartData1"
                  :options="chartOptions"
                  style="height: 450px; width: 100%;"
                  ref="refPie4"
                ></pie>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <div v-show="currentTab === 2">

          <v-row no-gutters class="mt-2">
            <v-col cols="12" sm="12">
              <v-card class="ma-8 pa-4" elevation="20">
                <v-row dense>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="3">
                    <v-select
                      :items="printChart"
                      v-model="printTarget"
                      item-text="name"
                      item-value="id"
                      placeholder="Печать отчета"
                      outlined
                      dense
                      hide-details
                      @change="printer"
                    />
                  </v-col>
                </v-row>
                <pie
                  :chart-data="chartData2"
                  :options="chartOptions"
                  style="height: 450px; width: 100%;"
                  ref="refPie4"
                ></pie>
              </v-card>
            </v-col>
          </v-row>
        </div>

        <div v-show="currentTab === 3">
          <v-row no-gutters class="mt-2">
            <v-col cols="12" sm="12">
              <v-card class="ma-8 pa-4" elevation="20">
                <v-row dense>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="3">
                    <v-select
                      :items="printChart"
                      v-model="printTarget"
                      item-text="name"
                      item-value="id"
                      placeholder="Печать отчета"
                      outlined
                      dense
                      hide-details
                      @change="printer"
                    />
                  </v-col>
                </v-row>
                <pie
                  :chart-data="chartData3"
                  :options="chartOptions"
                  style="height: 450px; width: 100%;"
                  ref="refPie4"
                ></pie>
              </v-card>
            </v-col>
          </v-row>
        </div>

        <div v-show="currentTab === 4">

          <v-row no-gutters class="mt-2">
            <v-col cols="12" sm="12">
              <v-card class="ma-8 pa-4" elevation="20">
                <v-row dense>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="3">
                    <v-select
                      :items="printChart"
                      v-model="printTarget"
                      item-text="name"
                      item-value="id"
                      placeholder="Печать отчета"
                      outlined
                      dense
                      hide-details
                      @change="printer"
                    />
                  </v-col>
                </v-row>
                <pie
                  :chart-data="chartData4"
                  :options="chartOptions"
                  style="height: 450px; width: 100%;"
                  ref="refPie4"
                ></pie>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>

    </v-card>
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import Pie from "../charts/Pie.js";
import { URL_API } from "../settings/apiConnect.js";
import Loader from "../components/Loader.vue";
import jsPDF from "jspdf";
import { saveAs } from "file-saver";

export default {
  name: "FacilityReportsTab",
  components: {
    Pie,
    Loader
  },
  data() {
    return {
      tabs: ["Зеленые насаждения", "Водные объекты", "Фонтаны", "Почва", "Отходы"],
      currentTab: 0,
      waterTypes: [
        { name: "Озеро", id: 0 },
        { name: "Пруд", id: 1 },
        { name: "Канал", id: 2 },
        { name: "Река", id: 3 },
        { name: "Гидротехническое сооружение", id: 4 },
        { name: "Арычная сеть", id: 5 },
        { name: "Прочие объекты", id: 6 },
        { name: "Точки", id: 7 },
        { name: "Водохранилища", id: 8 },
        { name: "Точки сбора анализов вод", id: 9 }
      ],
      waterTarget: 0,
      dataWaters: [],
      dataFountains: [],
      dataSoil: [],
      dataMunicipalwaste: [],

      dataWater: null,

      loading: false,
      table: false,
      plants: [],
      title: "Общие отчеты по городу",
      amount: null,
      typeTarget: null,
      reportsTarget: null,
      printTarget: null,
      sanitaryData: null,
      categoryData: null,
      diseaseData: null,
      verminData: null,
      eventData: null,
      chart: false,
      chartOptions: {
        title: {
          display: false,
          text: this.titlePie,
          fontColor: this.$vuetify.theme.dark ? "white" : "black",
          fontSize: 20
        },
        responsive: true,
        maintainAspectRatio: false,
        borderWidth: "10px",
        hoverBackgroundColor: "red",
        hoverBorderWidth: "10px",
        legend: {
          display: true,
          position: "right",
          labels: {
            fontColor: this.$vuetify.theme.dark ? "white" : "black",
            fontSize: 20
          }
        }
      },
      chartData: null,
      chartData1: null,
      chartData2: null,
      chartData3: null,
      chartData4: null,
      sanitaryColor: [
        { id: 1, color: "#19ae54" },
        { id: 2, color: "#febf2d" },
        { id: 3, color: "#36a2eb" },
        { id: 4, color: "#CD853F" },
        { id: 5, color: "#fffd38" },
        { id: 6, color: "#E46651" },
        { id: 8, color: "#98FB98" },
        { id: 9, color: "#FF7F50" },
        { id: 10, color: "#FFA07A" }
      ],
      defaultColor: [
        { id: 1, color: "#19ae54" },
        { id: 2, color: "#febf2d" },
        { id: 3, color: "#36a2eb" },
        { id: 4, color: "#CD853F" },
        { id: 5, color: "#fffd38" },
        { id: 6, color: "#E46651" },
        { id: 7, color: "#98FB98" },
        { id: 8, color: "#FF7F50" },
        { id: 9, color: "#FFA07A" },
        { id: 10, color: "#ed8cd2" },
        { id: 11, color: "#8B0000" },
        { id: 12, color: "#8A2BE2" },
        { id: 13, color: "#4B0082" },
        { id: 14, color: "#2F4F4F" },
        { id: 15, color: "#00BFFF" },
        { id: 16, color: "#008080" }
      ],
      reports: [
        { id: 1, report: "Отчет по состоянию" },
        { id: 2, report: "Отчет по категориям" },
        { id: 3, report: "Отчет по болезням" },
        { id: 4, report: "Отчет по вредителям" },
        { id: 5, report: "Отчет по хозяйственным мероприятиям" },
        { id: 6, report: "Отчет по видам растений" }
      ],
      printChart: [
        { id: 1, name: "Скачать рисунок PNG" },
        { id: 2, name: "Скачать рисунок JPEG" },
        { id: 3, name: "Скачать PDF документ" }
      ],
      headers: [
        {
          text: "Вид растения",
          align: "center",
          sortable: true,
          value: "name"
        },
        {
          text: "Количество",
          align: "center",
          sortable: true,
          value: "count"
        }
      ]
    };
  },

  computed: {
    ...mapGetters(["allLayersData"]),
    titlePie() {
      return this.$store.state.message.msg;
    },
    layerTarget: {
      get() {
        return this.$store.state.settings.layerTarget;
      },
      set(value) {
        this.$store.commit("updateLayerTarget", value);
      }
    },
    districtTarget: {
      get() {
        return this.$store.state.settings.districtTarget;
      },
      set(value) {
        this.$store.commit("updateDisrtictTarget", value);
      }
    },
    types() {
      return this.$store.state.types.typesData;
    },
    districts() {
      let arr = [];
      let city = { id: 0, name: "Весь город" };
      if (this.$store.state.user.userRole === 5) {
        this.$store.state.settings.disctricts.filter(el => {
          if (el.id === this.$store.state.user.currentUser.district_id)
            arr.push(el);
        });
        return arr;
      } else {
        this.$store.state.settings.disctricts.forEach(el => {
          arr.push(el);
        });
        arr.unshift(city);
        return arr;
      }
    },
    layers() {
      let arr = [];
      if (
        this.$store.state.user.userRole === 4 ||
        this.$store.state.user.userRole === 5
      ) {
        this.allLayersData.filter(el => {
          if (el.id === 0) arr.push(el);
        });
        return arr;
      } else {
        return this.allLayersData;
      }
    }
  },

  watch: {
    waterTarget: function(newId) {
      console.log(newId);
      if (this.dataWater.length > 0) {
        this.dataWater = this.dataWaters[newId];
        this.chartData1 = {
          hoverBackgroundColor: "red",
          hoverBorderWidth: 10,
          labels: this.dataWater.map(item => item.name),
          datasets: [
            {
              label: "Data One",
              backgroundColor: [
                "#FF6384",
                "#36A2EB",
                "#FFCE56",
                "#4BC0C0",
                "#9966FF",
                "#FF9F40",
                "#FFCD56",
                "#C9CBCF"
              ],
              data: this.dataWater.map(item => item.count)
            }
          ]
        };

        const sum = this.dataWaters[newId].reduce((acc, item) => acc + item.count, 0);
        this.$store.commit(
          "updateMessage",
          "Общее кол-во подотчетных объектов: " + sum,
          {
            root: true
          }
        );
      }
    },
    titlePie(newTitle) {
      this.chartOptions = {
        title: {
          display: true,
              text: newTitle,
              fontColor: this.$vuetify.theme.dark ? "white" : "black",
              fontSize: 20
        },
        responsive: true,
            maintainAspectRatio: false,
            borderWidth: "10px",
            hoverBackgroundColor: "red",
            hoverBorderWidth: "10px",
            legend: {
          display: true,
              position: "right",
              labels: {
            fontColor: this.$vuetify.theme.dark ? "white" : "black",
                fontSize: 20
          }
        }
      };
    },
    currentTab(newTab) {
      let sum = 0;
      switch (newTab) {
        case 0:
          sum = this.amount;
          break;
        case 1:
          sum = this.dataWaters[this.waterTarget].reduce((acc, item) => acc + item.count, 0);
          break;
        case 2:
          sum = this.dataFountains.reduce((acc, item) => acc + item.count, 0);
          break;
        case 3:
          sum = this.dataSoil.reduce((acc, item) => acc + item.count, 0);
          break;
        case 4:
          sum = this.dataMunicipalwaste.reduce((acc, item) => acc + item.count, 0);
          break;
      }

      this.$store.commit(
        "updateMessage",
        "Общее кол-во подотчетных объектов: " + sum,
        {
          root: true
        }
      );
    }
  },

  async mounted() {
    await this.fetchData('https://api.eco-monitor.kz/api/municipalwaste/reportbydistrict', 'dataMunicipalwaste');
    await this.fetchData('https://api.eco-monitor.kz/api/soil/reportbydistrict', 'dataSoil');
    await this.fetchData('https://api.eco-monitor.kz/api/fountain/reportbydistrict', 'dataFountains');
    await this.fetchWaterData();
  },

  methods: {
    fetchData(endpoint, dataProperty) {
      Vue.axios.get(endpoint)
        .then(response => {
          const modifiedData = response.data.map(item => ({
            ...item,
            name: `${item.name}: ${item.count}`
          }));
          this[dataProperty] = modifiedData;
          console.log(this[dataProperty]);
        })
        .catch(error => console.error(`Error fetching data from ${endpoint}:`, error));
    },

    fetchWaterData() {
      const waterEndpoints = [
        'https://api.eco-monitor.kz/api/lake/reportbydistrict',
        'https://api.eco-monitor.kz/api/pond/reportbydistrict',
        'https://api.eco-monitor.kz/api/channel/reportbydistrict',
        'https://api.eco-monitor.kz/api/river/reportbydistrict',
        'https://api.eco-monitor.kz/api/hydraulicstructure/reportbydistrict',
        'https://api.eco-monitor.kz/api/ditchnetwork/reportbydistrict',
        'https://api.eco-monitor.kz/api/otherobject/reportbydistrict',
        'https://api.eco-monitor.kz/api/point/reportbydistrict',
        'https://api.eco-monitor.kz/api/reservoir/reportbydistrict',
        'https://api.eco-monitor.kz/api/wateranalysispoint/reportbydistrict'
      ];

      Promise.all(waterEndpoints.map(url => Vue.axios.get(url)))
        .then(Vue.axios.spread((...responses) => {
          responses.forEach(response => {
            this.dataWaters.push(response.data.map(item => ({
              ...item,
              name: `${item.name}: ${item.count}`
            })));
          });
          console.log(this.dataWaters);
          this.loadChartData();
        }))
        .catch(errors => {
          console.error('Error fetching water data:', errors);
        });
    },

    loadChartData() {
      this.dataWater = this.dataWaters[0];

      this.chartData1 = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: this.dataWater.map(item => item.name),
        datasets: [
          {
            label: "Data One",
            backgroundColor: [
              "#FF6384",
              "#36A2EB",
              "#FFCE56",
              "#4BC0C0",
              "#9966FF",
              "#FF9F40",
              "#FFCD56",
              "#C9CBCF"
            ],
            data: this.dataWater.map(item => item.count)
          }
        ]
      };

      this.chartData2 = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: this.dataFountains.map(item => item.name),
        datasets: [
          {
            label: "Data One",
            backgroundColor: [
              "#FF6384",
              "#36A2EB",
              "#FFCE56",
              "#4BC0C0",
              "#9966FF",
              "#FF9F40",
              "#FFCD56",
              "#C9CBCF"
            ],
            data: this.dataFountains.map(item => item.count)
          }
        ]
      };

      this.chartData3 = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: this.dataSoil.map(item => item.name),
        datasets: [
          {
            label: "Data One",
            backgroundColor: [
              "#FF6384",
              "#36A2EB",
              "#FFCE56",
              "#4BC0C0",
              "#9966FF",
              "#FF9F40",
              "#FFCD56",
              "#C9CBCF"
            ],
            data: this.dataSoil.map(item => item.count)
          }
        ]
      };

      this.chartData4 = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: this.dataMunicipalwaste.map(item => item.name),
        datasets: [
          {
            label: "Data One",
            backgroundColor: [
              "#FF6384",
              "#36A2EB",
              "#FFCE56",
              "#4BC0C0",
              "#9966FF",
              "#FF9F40",
              "#FFCD56",
              "#C9CBCF"
            ],
            data: this.dataMunicipalwaste.map(item => item.count)
          }
        ]
      };

    },

    async pieReport(layerTarget, typeTarget, districtTarget) {
      this.plants = [];
      this.chart = false;
      this.loading = true;
      let response;
      let layer = "";
      if (layerTarget !== 0) layer = `&layer_id=${layerTarget}&`;
      let district = "";
      if (districtTarget !== 0) layer = `&district_id=${districtTarget}`;

      if (this.reportsTarget === 1) {
        response = await Vue.axios.get(
          `${URL_API}sanitary/getbyfilter?type_id=${typeTarget}${layer}${district}`
        );
        this.sanitaryData = response["data"];
        let arr = [];
        for (let s of this.sanitaryData) {
          let element = {};
          for (let c of this.sanitaryColor) {
            if (s.id === c.id) {
              (element.id = s.id),
                (element.name = s.name),
                (element.count = s.count),
                (element.color = c.color);
            }
          }
          arr.push(element);
        }
        this.amount = this.sanitaryData.reduce(
          (total, array) => total + array.count,
          0
        );
        this.$store.commit(
          "updateMessage",
          "Общее кол-во подотчетных объектов: " + this.amount,
          {
            root: true
          }
        );
        this.chartData = {
          hoverBackgroundColor: "red",
          hoverBorderWidth: 10,
          labels: arr
            .filter(el => el.count > 0)
            .map(el => el.name + ": " + el.count),
          datasets: [
            {
              label: "Data One",
              backgroundColor: arr
                .filter(el => el.count > 0)
                .map(el => el.color),
              data: arr.filter(el => el.count > 0).map(el => el.count)
            }
          ]
        };
      } else if (this.reportsTarget === 2) {
        response = await Vue.axios.get(
          `${URL_API}category/getbyfilter?type_id=${typeTarget}${layer}${district}`
        );
        this.categoryData = response["data"];
        let arr = [];
        for (let s of this.categoryData) {
          let element = {};
          for (let c of this.defaultColor) {
            if (s.id === c.id) {
              (element.id = s.id),
                (element.name = s.name),
                (element.count = s.count),
                (element.color = c.color);
            }
          }
          arr.push(element);
        }
        this.amount = this.categoryData.reduce(
          (total, array) => total + array.count,
          0
        );
        this.$store.commit(
          "updateMessage",
          "Общее кол-во подотчетных объектов: " + this.amount,
          {
            root: true
          }
        );
        this.chartData = {
          hoverBackgroundColor: "red",
          hoverBorderWidth: 10,
          labels: arr
            .filter(el => el.count > 0)
            .map(el => el.name + ": " + el.count),
          datasets: [
            {
              label: "Data One",
              backgroundColor: arr
                .filter(el => el.count > 0)
                .map(el => el.color),
              data: arr.filter(el => el.count > 0).map(el => el.count)
            }
          ]
        };
      } else if (this.reportsTarget === 3) {
        response = await Vue.axios.get(
          `${URL_API}disease/getbyfilter?type_id=${typeTarget}${layer}${district}`
        );
        this.diseaseData = response["data"];
        let arr = [];
        for (let s of this.diseaseData) {
          let element = {};
          for (let c of this.defaultColor) {
            if (s.id === c.id) {
              (element.id = s.id),
                (element.name = s.name),
                (element.count = s.count),
                (element.color = c.color);
            }
          }
          arr.push(element);
        }
        this.amount = this.diseaseData.reduce(
          (total, array) => total + array.count,
          0
        );
        this.$store.commit(
          "updateMessage",
          "Общее кол-во подотчетных объектов: " + this.amount,
          {
            root: true
          }
        );
        this.chartData = {
          hoverBackgroundColor: "red",
          hoverBorderWidth: 10,
          labels: arr
            .filter(el => el.count > 0)
            .map(el => el.name + ": " + el.count),
          datasets: [
            {
              label: "Data One",
              backgroundColor: arr
                .filter(el => el.count > 0)
                .map(el => el.color),
              data: arr.filter(el => el.count > 0).map(el => el.count)
            }
          ]
        };
      } else if (this.reportsTarget === 4) {
        response = await Vue.axios.get(
          `${URL_API}vermin/getbyfilter?type_id=${typeTarget}${layer}${district}`
        );
        this.verminData = response["data"];
        let arr = [];
        for (let s of this.verminData) {
          let element = {};
          for (let c of this.defaultColor) {
            if (s.id === c.id) {
              (element.id = s.id),
                (element.name = s.name),
                (element.count = s.count),
                (element.color = c.color);
            }
          }
          arr.push(element);
        }
        this.amount = this.verminData.reduce(
          (total, array) => total + array.count,
          0
        );
        this.$store.commit(
          "updateMessage",
          "Общее кол-во подотчетных объектов: " + this.amount,
          {
            root: true
          }
        );
        this.chartData = {
          hoverBackgroundColor: "red",
          hoverBorderWidth: 10,
          labels: arr
            .filter(el => el.count > 0)
            .map(el => el.name + ": " + el.count),
          datasets: [
            {
              label: "Data One",
              backgroundColor: arr
                .filter(el => el.count > 0)
                .map(el => el.color),
              data: arr.filter(el => el.count > 0).map(el => el.count)
            }
          ]
        };
      } else if (this.reportsTarget === 5) {
        response = await Vue.axios.get(
          `${URL_API}event/getbyfilter?type_id=${typeTarget}${layer}${district}`
        );
        this.eventData = response["data"];
        let arr = [];
        for (let s of this.eventData) {
          let element = {};
          for (let c of this.defaultColor) {
            if (s.id === c.id) {
              (element.id = s.id),
                (element.name = s.name),
                (element.count = s.count),
                (element.color = c.color);
            }
          }
          arr.push(element);
        }
        this.amount = this.eventData.reduce(
          (total, array) => total + array.count,
          0
        );
        this.$store.commit(
          "updateMessage",
          "Общее кол-во подотчетных объектов: " + this.amount,
          {
            root: true
          }
        );
        this.chartData = {
          hoverBackgroundColor: "red",
          hoverBorderWidth: 10,
          labels: arr
            .filter(el => el.count > 0)
            .map(el => el.name + ": " + el.count),
          datasets: [
            {
              label: "Data One",
              backgroundColor: arr
                .filter(el => el.count > 0)
                .map(el => el.color),
              data: arr.filter(el => el.count > 0).map(el => el.count)
            }
          ]
        };
      } else if (this.reportsTarget === 6) {
        let type;
        switch (typeTarget) {
          case 1:
            type = "breed";
            break;
          case 2:
            type = "bush";
            break;
          case 6:
            type = "flower";
            break;
          case 7:
            type = "plant";
            break;
          case 8:
            type = "vine";
            break;
          default:
            type = "";
        }
        if (type === "") {
          this.loading = false;
          this.table = true;
          return;
        }
        response = await Vue.axios.get(
          `${URL_API}${type}/getbyfilter?type_id=${typeTarget}${layer}${district}`
        );
        this.plants = response["data"];
        this.table = true;
      }
      if (this.reportsTarget <= 5) {
        this.chart = true;
      }
      this.loading = false;
    },

    tableReportExcel(layerTarget, typeTarget, districtTarget) {
      console.log("Эксель");
      let layer = "";
      if (layerTarget !== 0) layer = `&layer_id=${layerTarget}&`;
      let district = "";
      if (districtTarget !== 0) layer = `&district_id=${districtTarget}`;

      let type;
      switch (typeTarget) {
        case 1:
          type = "breed";
          break;
        case 2:
          type = "bush";
          break;
        case 6:
          type = "flower";
          break;
        case 7:
          type = "plant";
          break;
        case 8:
          type = "vine";
          break;
        default:
          type = "";
      }
      if (type === "") {
        return;
      }

      Vue.axios({
        url: `${URL_API}${type}/exportExcel?type_id=${typeTarget}${layer}${district}`,
        method: "GET",
        responseType: "blob"
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "report.xlsx");
        document.body.appendChild(link);
        link.click();
      });
    },

    printer() {
      this.chartOptions = {
        title: {
          display: true,
          text: this.$store.state.message.msg,
          fontColor: "black",
          fontSize: 20
        },
        responsive: true,
        maintainAspectRatio: false,
        borderWidth: "10px",
        hoverBackgroundColor: "red",
        hoverBorderWidth: "10px",
        legend: {
          display: true,
          position: "right",
          labels: {
            fontColor: "black",
            fontSize: 20
          }
        }
      };

      setTimeout(() => {
        let canvas = document.getElementById("pie-chart");

        if (this.printTarget === 1) {
          canvas.toBlob(function(blob) {
            saveAs(blob, "chart.png");
          });
        } else if (this.printTarget === 2) {
          canvas.toBlob(function(blob) {
            saveAs(blob, "chart.jpg");
          }, canvas.toDataURL("image/jpeg", 1.0));
        } else if (this.printTarget === 3) {
          let img = canvas.toDataURL();
          let pdf = new jsPDF("p", "mm", "a4");
          let width = pdf.internal.pageSize.getWidth();
          pdf.addImage(img, 0, 10, width, 0);
          pdf.save("chart.pdf");
        }
        this.chartOptions = {
          title: {
            display: true,
            text: this.$store.state.message.msg,
            fontColor: this.$vuetify.theme.dark ? "white" : "black",
            fontSize: 20
          },
          responsive: true,
          maintainAspectRatio: false,
          borderWidth: "10px",
          hoverBackgroundColor: "red",
          hoverBorderWidth: "10px",
          legend: {
            display: true,
            position: "right",
            labels: {
              fontColor: this.$vuetify.theme.dark ? "white" : "black",
              fontSize: 20
            }
          }
        };
      }, 1000);
    }
  }
};
</script>

<style scoped>
.tab-content > div {
  padding: 10px;
}
</style>